import React, { Component } from "react";
import Nav from "../../Nav";
import MetaTags from "react-meta-tags";
import "../Industries.css";
import "../../Table.css";
import Container from "../../Container";
import { NavLink } from "react-router-dom";
import Footer from "../../Footer";
class Defense extends Component {
  render() {
    return (
      <Container>
        <MetaTags>
          <meta
            id="meta-description"
            name="description"
            content="TrustThink has designed security frameworks that have been
                integrated with several high-level systems under leading defense organizations."
          ></meta>
        </MetaTags>
        <Nav />
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester">
              <h2 class="wide">Industries</h2>
              <h1 class="lead highfont">
              TrustThink collaborates with leaders in the transportation, healthcare, and defense sectors to navigate the complex landscape of 
              modern technology, ensuring security and innovation go hand in hand.
              </h1>

              <p class="opacity">
                <i></i>
              </p>

            </div>
          </div>
        </div>

        <body>
        <div class="grid10"> 
              <div class="imgtext">
                  <h2 class="title">
                    Defense 
                  </h2>
                  <img class="center defense_img" alt="Robotics-Assisted Medical Tools"> 
              </img>
                  <br></br>
                  <p>
                  The defense sector is undergoing a technological revolution, with the proliferation of unmanned systems and the increasing reliance on artificial intelligence and machine learning. 
                  These advancements are transforming traditional combat strategies and logistics, introducing new complexities in areas such as autonomous navigation, sensor integration, and real-time 
                  data processing. As systems become more interconnected and reliant on digital technologies, the potential for cybersecurity threats grows, necessitating sophisticated protective measures.
                  </p>

                  <p>
                  TrustThink works to help modernize cryptography and key management solutions to ensure interoperability, robustness, standardization, and sustainability. 
                  
                  </p>
                  
              </div>
              

           {/* Back button */}
           <NavLink to="/industries">
              <button type="button" class="back_btn">
                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>
            


          </div>

          

        

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
        </body>
        <Footer/>
      </Container>
    );
  }
}

export default Defense;
