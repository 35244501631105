import React, { Component } from "react";

import "./TableListSol.css";
import "../Table.css";
import Container from "../Container";
import { NavLink } from "react-router-dom";
import Footer from "../Footer";
import Nav from "../Nav";
import cmmImage from '../../img/aineuralnetwork.png';
import medicalImage from '../../img/medicalequipment.webp';
import droneImage from '../../img/drones-lg.webp';

class TableListSol extends Component {
  render() {
    return (
      <Container>
        <Nav/>
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester" id="head">
              <h2 class="wide">TrustThink Solutions</h2>
              <p class="opacity">
                <i></i>
              </p>
              <p class="lead highfont">
              Explore our tailored solutions that empower organizations to navigate technological changes and enhance security in a dynamic world.
              </p>
            </div>
          </div>
        </div>
        <body>
          
          <div class="grid10 addmargin">
            <div class="imgtext">
              
              <h2>Overview</h2>
              <p class="">
              In the rapidly evolving technological landscape, organizations face continuous challenges in keeping pace with advancements and ensuring security. TrustThink offers tailored solutions that 
              address these needs across various sectors. Our AI CMM Workshops help organizations assess their readiness for AI implementation, while our Medical Device Cybersecurity services ensure 
              compliance and security in healthcare technology. Additionally, our Aerial Drone Cybersecurity solution protects unmanned systems from emerging threats. These solutions empower organizations to
               confidently navigate technological changes and maintain robust security measures.
              </p>
            </div>

            <div class="threeCardCentered">
          
            <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
               
                    <img class="rounded-t-lg" src={cmmImage} alt="Neural networks" />
          
                <div class="p-5">
                   
                        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-grey">AI Integration Workshops</h5>
                  
                    <NavLink to="/cybersolutions/cmm"> 
                    <p class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Read more
                        <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </p>
                    </NavLink>
                </div>
            </div>

            
            <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
               
                    <img class="rounded-t-lg" src={medicalImage} alt="Electronic Medical device" />
              
                <div class="p-5">
                  
                        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-grey">Medical Device Cybersecurity</h5>
            
                  
                    <NavLink to="/cybersolutions/medical"> 
                    <p class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Read more
                        <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </p>
                    </NavLink>
                </div>
            </div>

                      
            <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
             
                    <img class="rounded-t-lg" src={droneImage} alt="Two drones in flight" />
           
                <div class="p-5">
                  
                        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-grey">Aerial Drone Cybersecurity</h5>
               
                    
                    <NavLink to="/cybersolutions/dronedefense"> 
                    <p class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Read more
                        <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </p>
                    </NavLink>
                </div>
            </div>
            </div>
            <div>
            </div>
            <div class="bottom">

              <a href="mailto:solutions@trustthink.net">
                          <strong>SOLUTIONS@TRUSTTHINK.NET</strong>
              </a>

            </div>
          </div>

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
          />
        </body>
        <Footer/>

      </Container>
    );
  }
}

export default TableListSol;
