import React, { Component } from "react";
import Nav from "../../Nav";
import MetaTags from "react-meta-tags";
import "../Industries.css";
import "../../Table.css";
import Container from "../../Container";
import { NavLink } from "react-router-dom";
import Footer from "../../Footer";
import ButtonLearnMore from "../../ButtonLearnMore";

class Transportation extends Component {
  render() {
    return (
      <Container>
        <MetaTags>
          <meta
            id="meta-description"
            name="description"
            content="TrustThink has worked hand in hand with suppliers to 
                help them understand and meet the expectations that come with
                the changing standards of an ever-growing transportation industry."
          ></meta>
        </MetaTags>
        <Nav />
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester">
              <h2 class="wide">Industries</h2>
              <h1 class="lead highfont">
              TrustThink collaborates with leaders in the transportation, healthcare, and defense sectors to navigate the complex landscape of 
              modern technology, ensuring security and innovation go hand in hand.
              </h1>

              <p class="opacity">
                <i></i>
              </p>

            </div>
          </div>
        </div>

        <body>
        <div class="grid10"> 
              <div class="imgtext">
                  <h2 class="title">
                    Supporting the Transportation Evolution
                  </h2>
                  <img class="transportation_img center" alt="Robotics-Assisted Medical Tools"> 
                  </img>
                  <br></br>
                  <p>
                    In an era defined by rapid technological evolution, the transportation industry is transforming. As vehicles and infrastructure become increasingly interconnected through cellular, 
                    satellite, and Wi-Fi networks, the need for robust cybersecurity measures has never been more critical. TrustThink is at the forefront of integrating advanced digital technologies into 
                    transportation systems, helping stakeholders navigate the complexities of new regulations and standards like UNECE WP.29 R155/R156 and ISO 21434.
                  </p>

                  <p>
                    At TrustThink, we leverage our deep expertise to develop security configurations for Intelligent Transportation Systems (ITS) and roadside equipment. 
                    We assist vehicle OEMs and suppliers in understanding compliance requirements and implementing cutting-edge security practices. Additionally, we provide State and Local Transportation 
                    Agencies with strategic roadmaps for deploying AI and ML technologies securely.
                  </p>

                  <p>
                    Our work spans from setting industry standards to creating secure frameworks for the operational technology that powers today's automated and connected vehicles. TrustThink is 
                    your partner in ensuring that as the transportation landscape evolves, it does so securely and intelligently.  
                  </p>

                 
        
                

   
         

          
        


                  
                  
                    
              </div>
          </div>

          <div class="grid3">
            
          <p>
                  
                  Work with TrustThink to:
                  <li>Develop security configurations for ITS and roadside equipment.</li>

                  <li>Navigate compliance with global standards like UNECE WP.29 and ISO 21434.</li>

                  <li>Build roadmaps for the secure implementation of AI and ML in transportation infrastructure.</li>

                  <li>Create resilient cybersecurity frameworks for connected and automated vehicles.</li>

                  <li>Harness our industry expertise to enhance the safety and efficiency of your transportation solutions.</li>
                  </p>
          <p class="flex flex-col items-center bg-gray-800 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl">
                      <div class="flex flex-col justify-between p-4 leading-normal">
                      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Identify gaps and create a strategic roadmap for AI adoption with our one day workshop.</h5>
                          <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Contact us today: <strong>solutions@trustthink.net</strong></p>
                    <ButtonLearnMore buttonLink="/cybersolutions/cmm"/>
                      </div>
                  </p>
      
                  
          </div>

          <div class="grid10">
           {/* Back button */}
           <NavLink to="/industries">
              <button type="button" class="back_btn">
                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>
          </div>
          

          

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
        </body>
        <Footer/>
      </Container>
    );
  }
}

export default Transportation;
