import React, { Component } from "react";

import "../Table.css";
import Container from "../Container";
import cmmImage from '../../img/aineuralnetwork.png';
import Footer from "../Footer";
import { NavLink } from "react-router-dom";
import Nav from "../Nav";

class CyberSolCMM extends Component {
  render() {
    return (
      <Container>
        <Nav/>
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester" id="head">
              <h2 class="wide">TrustThink Solutions</h2>
              <p class="opacity">
                <i></i>
              </p>
              <p class="lead highfont">
              Explore our tailored solutions that empower organizations to navigate technological changes and enhance security in a dynamic world.
              </p>
            </div>
          </div>
        </div>
        <body>
          <div class="grid10 addmargin">

          <div class="title">
          <h2>AI Integration Workshops: Navigating the Path to AI Readiness for State
                 and Local Transportation Industries.</h2>
            </div>

            <img class="images" src={cmmImage} alt="Neural network connections" style= {{height: 200, width: 300}}/>

            <div class="imgtext">
          
              <p class>
              Unlock the full potential of Artificial Intelligence within your organization with 
              our AI Integration Workshops. Designed for State and Local Transportation Agencies 
              (SLTTs) and beyond, these workshops leverage our AI Capability Maturity Model (AI CMM)
               to provide a comprehensive roadmap for AI adoption. Our expert-led sessions guide you 
               through evaluating and enhancing your organization's AI readiness across key dimensions,
               including Culture, Staffing, Collaboration, Business Processes, Cybersecurity, Data Infrastructure,
               and AI Applications. Whether you're just beginning your AI journey or looking to refine your 
               strategy, our workshops offer actionable insights, strategic planning, and hands-on experiences 
               to accelerate your path to AI maturity. Empower your team with the knowledge and tools needed 
               for successful AI integration and transformative growth.

            
            
              </p>

             
            </div>

            <p class="center flex flex-col items-center bg-gray-800 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl">
                        <div class="flex flex-col justify-between p-4 leading-normal">
                        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Learn more about Transportation-based CMMs:</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                              <li>
                                <a href="https://ops.fhwa.dot.gov/tsmoframeworktool/cmf_overview.htm" target="_blank">
                                  TSMO CMF
                                </a>
                              </li>
                              <li>
                                <a href="https://transportationops.org/publications/guidelines-applying-capability-maturity-model-analysis-connected-and-automated-vehicle" target="_blank">
                                  TSMO for CAV Deployments
                                </a>
                              </li>
                              <li>
                                <a href="https://ops.fhwa.dot.gov/tsmoframeworktool/available_frameworks/index.htm" target="_blank">
                                  Other FHWA CMM/CMFs
                                </a>
                              </li>


                            </p>
                        </div>
                        </p>

             
            <div class="bottom">

           

              <a href="mailto:solutions@trustthink.net">
                          <strong>SOLUTIONS@TRUSTTHINK.NET</strong>
              </a>
              
              
            </div>
            {/* Back button */}
            <NavLink to="/cybersolutions/overview">
              <button type="button" class="back_btn">                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>

          </div>

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
          />
        </body>
        <Footer/>
      </Container>
    );
  }
}

export default CyberSolCMM;
