import React, { Component } from "react";
import Nav from "../Nav";
import "./Hero.css";

import HeroServices from "../HeroServices";

import Container from "../Container";

class Hero extends Component {
  render() {
    return (
      <Container>
        <Nav />
        <div id="hero_div">
          <div id="hero_text_div">
            <h1>TrustThink</h1>
            <p>
            Cybersecurity for Connected And Autonomous Transportation, Defense and Healthcare
            </p>
          </div>
          {/* <div id="buttons_div"></div> */}
        </div>
            
        <HeroServices />
      </Container>
    );
  }
}

export default Hero;
